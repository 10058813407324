html, body, #stageplayer {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

#stageplayer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: auto;
}

#root {
  z-index: 2;
}